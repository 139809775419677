// src/components/About.tsx
import React from 'react';
import RoomGallery from './RoomGallery';
import {Typography} from "@mui/material";

const About: React.FC = () => {
    const masterBedroomImages = {
        images: [
            {src: '/images/4-master-bedroom-3.jpg', alt: ''},
            {src: '/images/4-master-bedroom-1.jpg', alt: ''},
            {src: '/images/4-master-bedroom-2.jpg', alt: ''},
        ]
    }
    const masterBathroomImages = {
        images: [
            {src: '/images/5-master-bathroom-1.jpg', alt: ''},
            {src: '/images/5-master-bathroom-2.jpg', alt: ''},
        ]
    }

    const secondBedroomImages = {
        images: [
            {src: '/images/6-bedroom-1.jpg', alt: ''},
            {src: '/images/6-bedroom-2.jpg', alt: ''},
            {src: '/images/6-bedroom-3.jpg', alt: ''},
        ]
    }

    const secondBathroomImages = {
        images: [
            {src: '/images/7-bathroom-1.jpg', alt: ''},
        ]
    }

    const livingRoomImages = {
        images: [
            {src: '/images/2-living-room-1.jpg', alt: ''},
            {src: '/images/2-living-room-2.jpg', alt: ''},
            {src: '/images/2-living-room-3.jpg', alt: ''},
            {src: '/images/2-living-room-4.jpg', alt: ''},
            {src: '/images/2-living-room-5.jpg', alt: ''},
            {src: '/images/2-living-room-6.jpg', alt: ''},
            {src: '/images/2-living-room-7.jpg', alt: ''},
        ]
    }

    const kitchenImages = {
        images: [
            {src: '/images/3-kitchen-2.jpg', alt: ''},
            {src: '/images/3-kitchen-1.jpg', alt: ''},
        ]
    }
    const terraceImages = {
        images: [
            {src: '/images/8-terrace-1.jpg', alt: ''},
            {src: '/images/8-terrace-2.jpg', alt: ''},
        ]
    }

    const solariumImages = {
        images: [
            {src: '/images/11-sundeck-1.jpg', alt: ''},
            {src: '/images/11-sundeck-2.jpg', alt: ''},
        ]
    }

    return (
        <div>
            <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">O apartmánu</Typography>
            <Typography variant="body1" gutterBottom>Máme úplně nově zařízený apartmán v urbanizaci Mijas Golf nad Fuengirolou. Je to klidné tiché
                místo s výhledem na golfové hřiště a
                hory. Všechny pokoje jsou vybaveny zbrusu novými klimatizačními jednotkami s nízkou hlučností značky Johnson (na fotkách je jesště
                nanajdete).
            </Typography>

            <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">Ložnice 1</Typography>
            <RoomGallery images={masterBedroomImages.images}/>
            <Typography variant="body1" sx={{mb: 4}}>
                Hlavní ložnice má 160cm manželskou postel s pohodlnou matrací. Nabízí přímý výstup na terasu a soukromou koupelnu před kterou
                je šatna s šatním stojanem. U postele jsou noční stolky. Je zde místo i na rozkladací postýlku pro děti, kterou na požádání bezplatně
                půjčime
            </Typography>
            <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">Koupelna 1</Typography>

            <RoomGallery images={masterBathroomImages.images}/>
            <Typography variant="body1" sx={{mb: 4}}>
                Hlavní koupelna se sprchovým koutem je přístupná z hlavní ložnice.
            </Typography>

            <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">Ložnice 2</Typography>
            <RoomGallery images={secondBedroomImages.images}/>
            <Typography variant="body1" sx={{mb: 4}}>
                Druhá ložnice má stejnou 160cm manželkou postel s pohodlnou matrací. Vedle postele jsou noční stolečky s lampičkami a před postelí
                vestavěná šatní skříň se zrcadlem. V tomto pokoji také najdete skříňku s dětskými hračkami.
            </Typography>

            <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">Koupelna 2</Typography>
            <RoomGallery images={secondBathroomImages.images}/>
            <Typography variant="body1" sx={{mb: 4}}>
                Druhá koupelna je blízko druhé ložnice a má vanu. Je také vybavena fénem.
            </Typography>
            <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">Obývák</Typography>
            <RoomGallery images={livingRoomImages.images}/>
            <Typography variant="body1" sx={{mb: 4}}>
                Prostorný obývací pokoj spojený s kuchyňským koutem se skládá z dvou pohodlných gaučů, které lze rozložit na 140cm manželskou postel.
                Je zde nová Samsung televize s úhlpříčkou 55 palců a předplaceným ClickTV s více jak 80 programy, vesměs v španělštině a angličtine.
                Samozřejmostí jsou
                aplikace jako Netflix a další, kde se můžete přihlásit do svého účtu.
            </Typography>
            <Typography variant="body1" sx={{mb: 4}}>
                V obývacím pokoji je také obrovský, masivní jídelní stůl s 6 židlemi a klimatizace. Posuvnými dveřmi se dostanete na terasu.
            </Typography>
            <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">Kuchyň</Typography>
            <RoomGallery images={kitchenImages.images}/>
            <Typography variant="body1" sx={{mb: 4}}>
                Plně vybavená kuchyně s varnou deskou, integrovanou lednici a myčkou. Nechybí ani topinkovač, rychlovarná konvice
                a nespresso kávovar. Samozřejmností je veškeré běžné nádobí tak aby se dala kuchyně pohodlně používat až 6 lidmi.
            </Typography>

            <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">Terasa</Typography>
            <RoomGallery images={terraceImages.images}/>
            <Typography variant="body1" sx={{mb: 4}}>
                Prostorná 20m² terasa s výhledem na golfové hřiště a hory. Je zde stůl s 6 židlemi a velký obdélníkový slunečník. Na terase je také
            </Typography>

            <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">Solárium</Typography>
            <RoomGallery images={solariumImages.images}/>
            <Typography variant="body1" sx={{mb: 4}}>
                Střecha nabízí soukromou 40m² sluneční terasu s lehátky a výhledem na moře a hory. Je zde také stejný slunečník jako na terase.
            </Typography>
        </div>
    );
};

export default About;
