import React, {useEffect, useState} from 'react';
import ZoomableImageCard from './ZoomableImageCard';

interface GalleryProps {
    images: { src: string; alt: string }[];
}

const RoomGallery: React.FC<GalleryProps> = ({images}) => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const handleOpen = (index: number) => {
        debugger
        setOpenIndex(index);
    };

    const handleClose = () => {
        setOpenIndex(null);
    };

    const handleNext = () => {
        debugger
        if (openIndex === images.length - 1) {
            return
        }
        setOpenIndex(prev => (prev === null ? null : (prev + 1) % images.length));
    };

    const handlePrev = () => {
        debugger
        if (openIndex === 0) {
            return
        }
        setOpenIndex(prev => (prev === null ? null : (prev - 1 + images.length) % images.length));
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'ArrowRight') {
            handleNext();
        } else if (event.key === 'ArrowLeft') {
            handlePrev();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []); // Passing an empty array to ensure this effect runs only once after the component mounts


    return (
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'left'}}>
            {images.map((image, index) =>
                (
                    <div key={index}>
                        <ZoomableImageCard
                            src={image.src}
                            alt={image.alt}
                            onClick={() => handleOpen(index)}
                            open={index === openIndex}
                            index={index}
                            imageCount={images.length}
                            handleClose={handleClose}
                            handleNext={handleNext}
                            handlePrev={handlePrev}
                        />
                    </div>
                ))}
        </div>
    );
};
export default RoomGallery;
