import { BrowserRouter as Router, Link, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Gallery from './components/Gallery';
import Prices from './components/Prices';
import Calendar from './components/Calendar';
import Contact from './components/Contact';

import {
    AppBar,
    Box,
    Button,
    Container,
    createTheme,
    CssBaseline,
    ThemeProvider,
    Toolbar,
    Typography,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from "react";

// Create a custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#1565c0', // A serene blue for primary actions and headers
        },
        secondary: {
            main: '#ed6c02', // A warm, inviting orange for highlights and calls to action
        },
        background: {
            default: '#f5f5f5', // A soft background to keep the focus on content
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 400,
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
        },
    },
});

interface NavLinkButtonProps {
    to: string;
    label: string;
    onClick?: () => void;
}

const NavLinkButton: React.FC<NavLinkButtonProps> = ({ to, label, onClick }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <Button
            component={Link}
            to={to}
            onClick={onClick}
            sx={{
                color: 'white',
                textDecoration: isActive ? 'underline' : 'none',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.25)',
                },
            }}
        >
            {label}
        </Button>
    );
}

function App() {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Casa Mariana
            </Typography>
            <List>
                <ListItem button component={Link} to="/" key="Úvod">
                    <ListItemText primary="Úvod" />
                </ListItem>
                <ListItem button component={Link} to="/about" key="O apartmánu">
                    <ListItemText primary="O apartmánu" />
                </ListItem>
                <ListItem button component={Link} to="/gallery" key="Galerie">
                    <ListItemText primary="Galerie" />
                </ListItem>
                <ListItem button component={Link} to="/prices" key="Ceník">
                    <ListItemText primary="Ceník" />
                </ListItem>
                {/* <ListItem button component={Link} to="/calendar" key="Kalendář">
                    <ListItemText primary="Kalendář" />
                </ListItem> */}
                <ListItem button component={Link} to="/contact" key="Kontakt">
                    <ListItemText primary="Kontakt" />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Box display="flex" flexDirection="column" minHeight="100vh">
                    <AppBar position="fixed" color="primary" elevation={1}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                                Casa Mariana
                            </Typography>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <NavLinkButton to="/" label="Úvod" />
                                <NavLinkButton to="/about" label="O apartmánu" />
                                <NavLinkButton to="/gallery" label="Galerie" />
                                <NavLinkButton to="/prices" label="Ceník" />
                                {/* <NavLinkButton to="/calendar" label="Kalendář" /> */}
                                <NavLinkButton to="/contact" label="Kontakt" />
                            </Box>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ display: { xs: 'block', sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Box component="nav">
                        <Drawer
                            anchor="left"
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                    <Container maxWidth="lg" sx={{ mt: 12, flexGrow: 1 }}>
                        <Routes>
                            <Route path="/about" element={<About />} />
                            <Route path="/gallery" element={<Gallery />} />
                            <Route path="/prices" element={<Prices />} />
                            {/* <Route path="/calendar" element={<Calendar />} /> */}
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/" element={<Home />} />
                        </Routes>
                    </Container>
                    {/* Footer */}
                    <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                        <Typography variant="h6" align="center" gutterBottom>
                            Casa Mariana
                        </Typography>
                        <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
                            &copy; 2024 Casa Mariana. Všechna práva vyhrazena.
                        </Typography>
                    </Box>
                </Box>
            </Router>
        </ThemeProvider>
    );
}

export default App;
