import React from 'react';
import {Box, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import PersonIcon from '@mui/icons-material/Person';

const Contact: React.FC = () => {
    return (
        <Box>
            <Typography variant="h4" component="h2" gutterBottom fontWeight="bold">
                Kontakt
            </Typography>
            <Typography variant="body1" gutterBottom>
                Pro rezervaci nas prosim kontaktujte
            </Typography>

            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <List>
                        <ListItem>
                            <ListItemIcon><PersonIcon/></ListItemIcon>
                            <ListItemText primary="Jméno:" secondary="Mariana Hesoun"/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><WhatsAppIcon/></ListItemIcon>
                            <ListItemText primary="WhatsApp:"
                                          secondary={<Link href="https://wa.me/00420723892180" target="_blank" rel="noopener noreferrer">+420 723 892
                                              180</Link>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FacebookIcon/></ListItemIcon>
                            <ListItemText primary="Facebook:"
                                          secondary={<Link href="https://www.facebook.com/mariana.aubrechtova" target="_blank"
                                                           rel="noopener noreferrer">Mariana
                                              Hesoun</Link>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><EmailIcon/></ListItemIcon>
                            <ListItemText primary="Email:"
                                          secondary={<Link href="mailto:casa.mariana.malaga">mariana.hesoun@gmail.com</Link>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><PhoneIcon/></ListItemIcon>
                            <ListItemText primary="Phone:" secondary="+420 723 890 180"/>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <List>
                        <ListItem>
                            <ListItemIcon><PersonIcon/></ListItemIcon>
                            <ListItemText primary="Jméno:" secondary="Jakub Hesoun"/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><WhatsAppIcon/></ListItemIcon>
                            <ListItemText primary="WhatsApp:"
                                          secondary={<Link href="https://wa.me/00420731109887" target="_blank" rel="noopener noreferrer">+420 731 109
                                              887</Link>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><FacebookIcon/></ListItemIcon>
                            <ListItemText primary="Facebook:"
                                          secondary={<Link href="https://www.facebook.com/jakub.hesoun" target="_blank" rel="noopener noreferrer">Jakub
                                              Hesoun</Link>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><EmailIcon/></ListItemIcon>
                            <ListItemText primary="Email:"
                                          secondary={<Link href="mailto:casa.mariana.malaga">hesoun.j@gmail.com</Link>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><PhoneIcon/></ListItemIcon>
                            <ListItemText primary="Phone:" secondary="+420 731 109 887"/>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Contact;
