import React from "react";
import Slider from "react-slick";
import {Box, styled} from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ImageWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    maxHeight: '500px',
    height: '500px'
});

export const Image = styled('img')({
    width: '100%',
    height: '100%', // Ensure it covers the container
    objectFit: 'cover', // This ensures the image covers the entire container
    objectPosition: 'center center', // This centers the image within the container
});

const StyledSlider = styled(Slider)({
    ".slick-dots li button:before": {
        color: "#fff", // Change the color to something visible
        fontSize: "12px",
    },
    ".slick-dots li.slick-active button:before": {
        color: "#fff", // Change the active dot color to something visible
    }
});

const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
    };

    return (
        <Box sx={{maxHeight: '500px', overflow: 'hidden'}}>
            <StyledSlider {...settings}>
                <ImageWrapper>
                    <Image src="/images/1.jpeg" alt="1"/>
                </ImageWrapper>
                <ImageWrapper>
                    <Image src="/images/2-living-room-1.jpg" alt="2"/>
                </ImageWrapper>
                <ImageWrapper>
                    <Image src="/images/2-living-room-2.jpg" alt="2"/>
                </ImageWrapper>
                <ImageWrapper>
                    <Image src="/images/2-living-room-3.jpg" alt="2"/>
                </ImageWrapper>
                <ImageWrapper>
                    <Image src="/images/4-master-bedroom-1.jpg" alt="3"/>
                </ImageWrapper>
                <ImageWrapper>
                    <Image src="/images/6-bedroom-1.jpg" alt="3"/>
                </ImageWrapper>
                <ImageWrapper>
                    <Image src="/images/8-terrace-1.jpg" alt="3"/>
                </ImageWrapper>
                <ImageWrapper>
                    <Image src="/images/z.jpg" alt="3"/>
                </ImageWrapper>
            </StyledSlider>
        </Box>
    );
};

export default Carousel;
