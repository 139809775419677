import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
    {
        original: '/images/2-living-room-1.jpg',
        thumbnail: '/images/2-living-room-1.jpg',
        description: ''
    },
    {
        original: '/images/2-living-room-2.jpg',
        thumbnail: '/images/2-living-room-2.jpg',
        description: ''
    },
    {

        original: '/images/2-living-room-3.jpg',
        thumbnail: '/images/2-living-room-3.jpg',
        description: ''
    },
    {

        original: '/images/2-living-room-4.jpg',
        thumbnail: '/images/2-living-room-4.jpg',
        description: ''
    },
    {
        original: '/images/2-living-room-5.jpg',
        thumbnail: '/images/2-living-room-5.jpg',
        description: ''
    },

    {
        original: '/images/2-living-room-6.jpg',
        thumbnail: '/images/2-living-room-6.jpg',
        description: ''
    },
    {

        original: '/images/2-living-room-7.jpg',
        thumbnail: '/images/2-living-room-7.jpg',
        description: ''
    },
    {
        original: '/images/1.jpeg',
        thumbnail: '/images//images/1.jpeg',
        description: 'Terase view'
    },
    {

        original: '/images/3-kitchen-1.jpg',
        thumbnail: '/images/3-kitchen-1.jpg',
        description: ''
    },

    {
        original: '/images/3-kitchen-2.jpg',
        thumbnail: '/images/3-kitchen-2.jpg',
        description: ''
    },

    {
        original: '/images/4-master-bedroom-1.jpg',
        thumbnail: '/images/4-master-bedroom-1.jpg',
        description: ''
    },

    {
        original: '/images/4-master-bedroom-2.jpg',
        thumbnail: '/images/4-master-bedroom-2.jpg',
        description: ''
    },

    {
        original: '/images/4-master-bedroom-3.jpg',
        thumbnail: '/images/4-master-bedroom-3.jpg',
        description: ''
    },

    {

        original: '/images/5-master-bathroom-1.jpg',
        thumbnail: '/images/5-master-bathroom-1.jpg',
        description: ''
    },

    {

        original: '/images/5-master-bathroom-2.jpg',
        thumbnail: '/images/5-master-bathroom-2.jpg',
        description: ''
    },

    {

        original: '/images/6-bedroom-1.jpg',
        thumbnail: '/images/6-bedroom-1.jpg',
        description: ''
    },

    {
        original: '/images/6-bedroom-2.jpg',
        thumbnail: '/images/6-bedroom-2.jpg',
        description: ''
    },

    {

        original: '/images/6-bedroom-3.jpg',
        thumbnail: '/images/6-bedroom-3.jpg',
        description: ''
    },

    {
        original: '/images/7-bathroom-1.jpg',
        thumbnail: '/images/7-bathroom-1.jpg',
        description: ''
    },

    {

        original: '/images/8-terase-1.jpg',
        thumbnail: '/images/8-terase-1.jpg',
        description: ''
    },

    {
        original: '/images/8-terase-2.jpg',
        thumbnail: '/images/8-terase-2.jpg',
        description: ''
    },

    {
        original: '/images/9-hall.jpg',
        thumbnail: '/images/9-hall.jpg',
        description: ''
    },

    {
        original: '/images/10-washing-machine.jpg',
        thumbnail: '/images/10-washing-machine.jpg',
        description: ''
    },

    {

        original: '/images/11-sundeck-1.jpg',
        thumbnail: '/images/11-sundeck-1.jpg',
        description: ''
    },

    {

        original: '/images/11-sundeck-2.jpg',
        thumbnail: '/images/11-sundeck-2.jpg',
        description: ''
    },

    {
        original: '/images/z.jpg',
        thumbnail: '/images/z.jpg',
        description: ''
    }
];

const Gallery = () => {
    return <ImageGallery items={images}/>;
};

export default Gallery;
