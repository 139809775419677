import React from 'react';
import {Card, CardMedia, Dialog, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface ImageProps {
    src: string;
    alt: string;
    title?: string;
    onClick: () => void;
    open: boolean;
    index: number;
    imageCount: number,
    handleClose: () => void;
    handleNext: () => void;
    handlePrev: () => void;
}

const ZoomableImageCard: React.FC<ImageProps> = ({src, alt, onClick, open,index, imageCount, handleClose, handleNext, handlePrev}) => {
    return (
        <>
            <Card onClick={onClick}
                  elevation={3}
                  sx={{
                      margin: 2,
                      maxWidth: 345,
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transitions for transform and shadow
                      '&:hover': {
                          transform: 'scale(1.03)', // Slight scale up on hover
                          boxShadow: 10 // Increase shadow depth on hover for a more "lifted" effect
                      }
                  }}
            >
                <CardMedia
                    component="img"
                    height="194"
                    image={src}
                    alt={alt}
                    sx={{
                        width: '100%', // Ensures the image fills the card
                        transition: 'transform 0.3s ease', // Smooth transition for the transform
                        '&:hover': {
                            transform: 'scale(1.1)' // Scale up image on hover for emphasis
                        }
                    }}
                />
            </Card>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <IconButton aria-label="close" onClick={handleClose} sx={{position: 'absolute', right: 8, top: 8}}>
                    <CloseIcon/>
                </IconButton>
                {open && (
                    <>
                        {index !== 0 && (
                            <IconButton
                                aria-label="previous"
                                onClick={handlePrev}
                                sx={{position: 'absolute', left: 8, top: '50%', transform: 'translateY(-50%)', zIndex: 1}}
                            >
                                <ArrowBackIosIcon/>
                            </IconButton>
                        )}
                        {index !== imageCount - 1 && (
                        <IconButton
                            aria-label="next"
                            onClick={handleNext}
                            sx={{position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)', zIndex: 1}}
                        >
                            <ArrowForwardIosIcon/>
                        </IconButton>
                        )}
                        <img src={src} alt={alt} style={{width: '100%', height: 'auto'}}/>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default ZoomableImageCard;
