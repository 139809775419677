import React from 'react';
import { Box, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import BedroomIcon from '@mui/icons-material/KingBed';
import PeopleIcon from '@mui/icons-material/People';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import HomeIcon from '@mui/icons-material/House';
import WifiIcon from '@mui/icons-material/Wifi';
import TvIcon from '@mui/icons-material/Tv';
import PoolIcon from '@mui/icons-material/Pool';
import KeyIcon from '@mui/icons-material/VpnKey';
import CountertopsOutlinedIcon from '@mui/icons-material/CountertopsOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import AirplanemodeActiveOutlinedIcon from '@mui/icons-material/AirplanemodeActiveOutlined';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; // Import the car icon
import Carousel from './Carousel';
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: 36.54874695271357,
    lng: -4.658122816177132
};

const Home: React.FC = () => {
    return (
        <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Carousel />

            <Typography variant="h4" component="h1" gutterBottom fontWeight="bold" sx={{ mt: 2 }}>
                Apartmán Casa Mariana
            </Typography>
            <Typography variant="body1" gutterBottom>
                Úplně nově zřízený apartmán v Mijas Golf nad Fuengirolou. Je to klidné, tiché misto s výhledem na golfové hřiště a hory.
            </Typography>

            <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><BedroomIcon /></ListItemIcon>
                        <ListItemText primary="2 ložnice, 2 koupelny" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Maximálně 6 osob" secondary="Po domluvě lze za příplatek ubytovat až 8 lidí (rodiny s dětmi)" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><ChildFriendlyIcon /></ListItemIcon>
                        <ListItemText primary="Vhodné pro děti: dětská židlička, prkénko, nádobí, postýlka, knížky, hračky" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary="90m² vnitřní podlahové plochy, 20m² terasa a dalších 40m² opalovací sundeck s lehátky na střeše" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><AcUnitOutlinedIcon /></ListItemIcon>
                        <ListItemText primary="Nová klimatizace ve všech pokojích (není na fotkách)" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><CountertopsOutlinedIcon /></ListItemIcon>
                        <ListItemText primary="Plně vybavená kuchyně s integrovanou lednicí a myčkou včetně kávovaru, toustovače a rychlovarné konvice" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><WifiIcon /></ListItemIcon>
                        <ListItemText primary="Vysokorychlostní WIFI internet" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><TvIcon /></ListItemIcon>
                        <ListItemText primary="55'' smart TV s IPTV" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><PoolIcon /></ListItemIcon>
                        <ListItemText primary="V rezortu 2 bazény, paddle court" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><KeyIcon /></ListItemIcon>
                        <ListItemText primary="Samoobslužný proces ubytování přes smart zámek" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><AirplanemodeActiveOutlinedIcon /></ListItemIcon>
                        <ListItemText primary="25 minut autem z letiště" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                        <ListItemText primary="5 minut autem k obchodům a 10 minut k pláži ve Fuengirole" />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon><DirectionsCarIcon /></ListItemIcon>
                        <ListItemText primary="Parkování na ulici před domem anebo ve sdílené garáži"
                                      secondary="Počet míst v garáži je omezen, je vhodnější spíše pro menší až střední auta"/>
                    </ListItem>
                </Grid>
            </Grid>

            <LoadScript googleMapsApiKey="AIzaSyBNk4XMC-Zau4M_KFSyoDRzT2tm-Ch1nJ8">
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={15}
                >
                    <Marker position={center}>
                        <InfoWindow position={center}>
                            <Typography variant="body2">Casa Mariana</Typography>
                        </InfoWindow>
                    </Marker>
                </GoogleMap>
            </LoadScript>
        </Box>
    );
};

export default Home;
